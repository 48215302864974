import React from "react";
import "./style.css";

export default function App({ name, callback }) {
  return (
    <div className="fab__container" ref={callback}>
      <h1>{name}</h1>
      <p>You wish this was your bank</p>
    </div>
  );
}
