import React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(
  <App
    name="Fucking Awesome Bank"
    callback={() => console.log("Fuck your bank, use ours")}
  />
);
